import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSession, useUser } from '@descope/react-sdk'
import { Layout } from '../components/layout/Layout'
import { ROUTE } from './Router.tsx'

import * as analytics from '../lib/analytics'
import * as observability from '../lib/observability'
import { useKyc } from '@/api/kyc.ts'
import { updateInfo } from '@/auth/analyticsUserProfile.ts'
import { useUserCredits } from '@/api/credits.ts'
import { useAvatar } from '@/src-ideation/hooks/useAvatar.ts'
import { useAccountInfoStore } from '@/store/useAccountInfoStore.ts'
import { isRunningInElectron } from '@/constants/generalFunctions.ts'
import IntegrationInput from '@/components/integrationInput/IntegrationInput.tsx'
import { onRequestHeaders } from '@/lib/app.ts'
import { FILE_SYNC_ENABLED } from '@/lib/config.ts'
import { usePopUp } from '@/api/popup.ts'
import { useOnboarding } from '@/store/useOnboarding.ts'

export const Root = React.memo(() => {
  const { isAuthenticated, isSessionLoading, sessionToken } = useSession()
  const { user, isUserLoading } = useUser()
  const navigate = useNavigate()
  const location = useLocation()
  const { checkKycData } = useKyc()
  const [isKycFetching, setIsKycFetching] = React.useState(false)
  const { getUserCredits } = useUserCredits()
  const { imageSrc } = useAvatar()
  const { setUserImage } = useAccountInfoStore(store => store)
  const { getPopUpData, putPopUp } = usePopUp()

  const { setIdeationSeenAt, setDesktopAppSeenAt, setIdeationTabButtonSeenAt, setChatSeenAt } = useOnboarding(
    store => store
  )

  const onHandleClose = () => {
    setIdeationTabButtonSeenAt(false)
    putPopUp('ideationTabButtonSeenAt')
  }

  useEffect(() => {
    if (user && isAuthenticated) {
      analytics.identify(user.userId)
      observability.setUser({
        id: user.userId,
        email: user.email as string,
        name: user.name as string,
      })

      updateInfo(user)

      analytics.track('User Authenticated', {
        identifiedWithId: user.userId,
      })
      setIsKycFetching(true)
      setUserImage(imageSrc)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isAuthenticated])

  useEffect(() => {
    if (user && isAuthenticated && isKycFetching) {
      getUserCredits().then(() => {})
      checkKycData().finally(() => {
        getPopUpData()
          .then(res => {
            const { chatSeenAt, ideationSeenAt, desktopAppSeenAt, ideationTabButtonSeenAt, tenantId } = res

            if (!chatSeenAt) {
              setChatSeenAt(true)
            }
            if (!ideationSeenAt) {
              setIdeationSeenAt(true)
            }
            if (!desktopAppSeenAt) {
              setDesktopAppSeenAt(true)
            }
            if (!ideationTabButtonSeenAt || !tenantId) {
              setIdeationTabButtonSeenAt(true)
            }
          })
          .catch((error: any) => {
            analytics.track('Error Fetching PopUp Data', { error })
            setIdeationTabButtonSeenAt(true)
            setDesktopAppSeenAt(true)
            setIdeationSeenAt(true)
            setChatSeenAt(true)
          })
      })
    }
  }, [isKycFetching])

  useEffect(() => {
    if (!isAuthenticated) {
      // Navigate only if the user is not trying to authenticate
      if (![ROUTE.SIGN_IN, ROUTE.SIGN_UP].includes(location.pathname)) {
        navigate(ROUTE.SIGN_IN)
      }
    }
  }, [isAuthenticated, navigate, location])

  useEffect(() => {
    setUserImage(imageSrc)
  }, [imageSrc])

  useEffect(() => {
    if (isRunningInElectron() && FILE_SYNC_ENABLED === true) {
      void onRequestHeaders()
    }
  }, [sessionToken])

  return (
    <>
      <Layout
        isSessionLoading={isSessionLoading}
        isAuthenticated={isAuthenticated}
        isUserLoading={isUserLoading}
        onHandleClose={onHandleClose}
      />

      {isRunningInElectron() && (
        <>
          <IntegrationInput />
        </>
      )}
    </>
  )
})
