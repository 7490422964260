import { IpcRendererEvent } from 'electron'
import { createHeaders } from '@/api/utils.ts'
import { TrackedDirectory } from '@/components/librarySection/library/Library.tsx'

export type OpenPDFOptions = {
  url: string
}
export type DownloadCadOptions = {
  url: string
}
export type resizeAppWindowOptions = {
  isOpen: boolean
}

export type receivedDataOptions = {
  [key: string]: any
}

export interface LocalFileTracking {
  trackedDirectories: TrackedDirectory[]
}

export type IpcCallbackWithData<T = any> = (event: IpcRendererEvent, data: T) => void

const app = () => (window as any).app

export async function openPDF(opts: OpenPDFOptions) {
  await app().openPDF(opts)
}

export async function resizeAppWindow(opts: resizeAppWindowOptions) {
  await app().resizeAppWindow(opts)
}

export function onReceivedData(callback: IpcCallbackWithData) {
  app().onReceivedData(callback)
}

export function onInputSubmitted(callback: IpcCallbackWithData | null) {
  app().onInputSubmitted(callback)
}
export function openInputWindow() {
  app().openInputWindow()
}

export function submitInput(inputText: string | undefined) {
  app().submitInput(inputText)
}

export function hideMinimizeButton(hide: boolean) {
  app().hideMinimizeButton(hide)
}

export function minimizeApp() {
  app().minimizeApp()
}

export function addListener<T = any>(eventName: string, callback: (data: T) => any) {
  app().on(eventName, callback)
}

export function onRemoveListener(eventName: string, callback: IpcCallbackWithData) {
  app().removeListener(eventName, callback)
}

export async function downloadCad(opts: DownloadCadOptions) {
  await app().downloadCad(opts)
}

export async function sendDownloadCadToSW() {
  await app().sendDownloadCadToSW()
}

export async function getFileSyncStatus(): Promise<LocalFileTracking> {
  return await app().getFileSyncStatus()
}

export async function addDirectoryToSync(): Promise<LocalFileTracking> {
  return await app().addDirectoryToSync()
}

export function removeDirectoryFromSync(filePath: string) {
  app().removeDirectoryFromSync(filePath)
}

export async function onRequestHeaders() {
  try {
    const headers = await createHeaders()
    app().sendHeadersResponse(headers)
  } catch (error) {
    console.error('Error sending headers response:', error)
  }
}
